import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,

  isAffiliated: true,

  rivalName: "purecasino",
  casinoId: 257,
  GA_TRACKING: "G-PQS08SMDM0",

  rivalChatGroupName: "Pure Casino",
  prettyName: "Pure Casino",
  contactEmail: "support@purecasino.co",
  docsEmail: "documents@pure.casino",

  assetsPath: "assets/brands/pure",

  publicPromotions: [
    {id: 'PARADISE8_1', name: "deposit", state: "eligible", details: "welcome", imgPath: "assets/brands/pure/promo.png"},
    {id: 2, name: "cashbackInsurance", state: "eligible", details: "welcome", imgPath: "assets/brands/pure/promo.png"},
    {id: 'PARADISE8_3', name: "nextDayCashback", state: "eligible", details: "weekly", imgPath: "assets/brands/pure/promo.png"},
    {id: 'PARADISE8_4', name: "loyalty", state: "eligible", details: "weekly", imgPath: "assets/brands/pure/promo.png"}
  ],

  //extra stuff in footer and in terms
  license: {
    pdb_name: "Pan De Bono Consulting Limited - HE401911",
    pdb_address: "84, Spyrou Kyprianou Avenue, 2nd floor, 4004, Limassol, Cyprus",
    lh_name: "Windward Circle B.V - 150466",
    lh_address: "Emancipatie Blvd. Dominco F. Don Martina 31, New Haven Office Ctr., Willemstad, Curaçao – Dutch Caribbean"
  },
  licenseNo: "#5536/JAZ",


  //SEO
  metaDescription: "Join and play online casino games at Pure Casino. Top online gambling with the latest slots, jackpot gamers, live dealers, baccarat and online casino essentials. Real cash welcome bonuses, deposit bonuses, loyalty program secure payments with fast payouts even in bitcoin.",
  logoName: "pure-casino-online-casino-logo.png",
  logoAltText: "Pure Casino official logo, featuring a clean and modern design with white and green text, representing a premium online casino offering a wide range of slots, table games, and live dealer experiences. Enjoy secure and transparent online gaming at Pure Casino."

};


